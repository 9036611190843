.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

#ticker {
  width: 100%;
  height: 80px;
}

.ticker-section {
  width: 100%;
  margin-bottom: 50px;
}
.label {
  font-size: 16px;
  margin-right: 15px;
}
.input {
  margin-right: 20px;
  border-radius: 4px;
  height: 25px;
  padding-left: 15px;
  border: 1px solid #131722;
}
.symbol {
  text-transform: uppercase;
}
.error {
  border: 1px solid red;
}
button {
  padding: 5px;
  border: 1px solid #131722;
  min-width: 90px;
  margin: 2px;
  cursor: pointer;
  text-transform: uppercase;
  background: #131722;
  color: white;
}
button[disabled] {
  pointer-events: none;
  cursor: disabled;
}
.close {
  cursor: pointer;
}
.item-proName {
  margin-right: 50px;
}
.ticker-list {
  padding-right: 20px;
  height: 130px;
  max-width: 450px;
  margin: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 50px;
}

/* width */
.ticker-list::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.ticker-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.ticker-list::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
}

/* Handle on hover */
.ticker-list::-webkit-scrollbar-thumb:hover {
  background: grey;
}

.ticker-item-container {
  display: flex;
  font-size: 15px;
}
.item {
  text-align: left;
  width: 97%;
}
.headline {
  font-size: 22px;
  font-weight: 300;
  margin-top: 0px;
}
.close {
  width: 3%;
}
svg {
  color: red;
  font-size: 15px;
  width: 15px;
  height: 15px;
}

.ticker-wrapper {
  height: 135px;
}
